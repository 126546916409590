.sketch-picker {
  box-shadow: initial !important;
  padding: 0 !important;
}

.sketch-picker * {
  font-family: inherit !important;
  color: inherit !important;
}

.sketch-picker > div:first-child {
  border-radius: 6px;
  margin-bottom: 3px;
}

.sketch-picker input {
  border-radius: 4px;
  border: 1px solid #E1DFEC !important;
  box-shadow: initial !important;
  font-size: 11px !important;
  padding-block: 3px !important;
}

.sketch-picker .flexbox-fix:last-child {
  margin: 0 !important;
  padding-inline: 0 !important;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(16px, 1fr));
  gap: 8px;
}

.sketch-picker .flexbox-fix:last-child > div {
  margin: 0 !important;
}

.sketch-picker .flexbox-fix:last-child > div:hover {
  transform: scale(1.15);}

