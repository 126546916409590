.editor .public-DraftStyleDefault-block {
  margin-top: 0;
  margin-bottom: 8px;
}

.public-DraftEditor-content {
  padding: 14px 16px;
}

.public-DraftEditorPlaceholder-root {
  padding: 16px 16px;
  color: #BCB8D0;
  font-size: 14px;
}

.toolbar {
  border: initial;
  border-radius: 6px;
  background: #f2f0f9;
  margin: 5px 5px 0 5px;
}

.rdw-option-wrapper, .rdw-dropdown-wrapper {
  border-radius: 6px;
  border: 1px solid #e1dfec;
  font-size: 14px;
}

.rdw-option-active, .rdw-option-wrapper:hover, .rdw-dropdown-wrapper:hover {
  box-shadow: initial;
  background: #daeefb;
  border: 1px solid #0090f0 !important;
}

.rdw-option-wrapper img {
  opacity: .75;
}

.rdw-dropdown-selectedtext {
  color: #5b5675 !important
}

.rdw-dropdown-carettoopen {
  top: 50%;
  transform: translateY(-50%);
  border-top-color: #5b5675;
}

.rdw-dropdown-optionwrapper, .rdw-emoji-modal {
  margin-top: 4px;
  border-radius: 6px;
  padding: 5px 0;
  box-shadow: 0 5px 10px #eeecf3;
  border: 1px solid #e1dfec;
}

.rdw-emoji-modal {
  padding: 5px;
  margin-top: 0;
}

.rdw-dropdown-optionwrapper:hover {
  box-shadow: 0 5px 10px #eeecf3;
}

.rdw-emoji-icon {
  border-radius: 6px;
  padding: 3px;
  margin: 0;
  height: 27px;
  width: 27px;
}

.rdw-emoji-wrapper[title=Emoji] {
  margin-left: auto;
}

.rdw-emoji-icon:hover {
  background: #f2f0f9;
}

.rdw-dropdownoption-default:hover, .rdw-dropdownoption-active {
  background: #f2f0f9;
}

.rdw-dropdownoption-default {
  padding: 2px 7px;
}

.DraftEditor-alignRight blockquote.rdw-right-aligned-block {
  border-left: initial;
  padding-left: initial;
  border-inline-end: 5px solid #f1f1f1;
  padding-inline-end: 5px;
  background: #fff;
}

.DraftEditor-editorContainer {
  max-height: 500px;
}
