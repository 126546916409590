.rhap_container {
  box-shadow: initial;
  font-size: 14px;
  padding: 0 0 0 69px;
  position: relative;
  background-color: transparent;
  margin-top: 0px ;
}

.rhap_time {
  font-size: 13px;
  color: inherit;
}

.rhap_time.rhap_total-time {
  position: absolute;
  right: 74px;
  top: -6px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='Icons_Time_clock-three' data-name='Icons/Time/clock-three' transform='translate(-2 -2)'%3E%3Cpath id='clock-three' d='M12.4,9.2H10.8V6A.8.8,0,1,0,9.2,6v4a.8.8,0,0,0,.8.8h2.4a.8.8,0,0,0,0-1.6ZM10,2a8,8,0,1,0,8,8A8,8,0,0,0,10,2Zm0,14.4A6.4,6.4,0,1,1,16.4,10,6.4,6.4,0,0,1,10,16.4Z' fill='%238c87a6'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  padding-left: 24px;
  height: 17px;
  display: flex;
  align-items: center;
}

.rhap_time.rhap_current-time {
  position: absolute;
  transform: translateY(23px);
}


.rhap_progress-container {
  margin-left: 0;
}

.rhap_main {
  flex-direction: row;
  max-width: calc(100% - 60px);
}

.rhap_stacked .rhap_controls-section {
  margin: 0;
  order: 0;
  max-width: 48px;
  width: 48px;
  height: 48px;
  flex-basis: 48px;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.rhap_main-controls-button {
  margin: 0;
  color: #0090F0;
  width: 48px;
  height: 48px;
  transition: opacity 120ms ease;
}

.rhap_main-controls-button:active {
  transform: scale(.96);
}


.rhap_main-controls-button svg {
  width: inherit;
  height: inherit;
}

.rhap_main-controls-button  svg path {
  transform: scale(1.20) translate(-2px, -2px);
}

.rhap_header {
  margin-bottom: 11px;
  margin-top: -4px;
}

.rhap_progress-bar {
  height: 7px;
  border-radius: 20px;
}

.rhap_progress-bar, .rhap_download-progress {
  border-radius: 20px;
}

.rhap_download-progress {
  background-color: #E1DFEC;
}

.rhap_progress-filled {
  border-radius: 20px 0 0 20px;
}

.rhap_progress-bar-show-download {
  background-color: #E1DFEC;
}

.rhap_progress-indicator {
  background-color: #0090F0;
  width: 18px;
  height: 18px;
  top: -6px;
  margin-left: -1px;
}

.rhap_progress-filled {
  background-color: #0090F0;
}
